import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:point?',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/offer',
    name: 'offer',
    component: () => import('../views/offer.vue')
  },
  {
    path: '/mosttradedaustralianshares',
    name: 'mosttradedaustralianshares',
    component: () => import('../views/mosttradedaustralianshares.vue')
  },
  {
    path: '/why-join-commsec',
    name: 'whyJoinCommsec',
    children: [
      {
        path: 'learn-about-the-markets',
        name: 'learnAboutTheMarkets',
        component: () => import('../views/why-join-commsec/learn-about-the-markets.vue'),
      },
      {
        path: 'choosing-an-online-broker',
        name: 'choosingAnOnlineBroker',
        component: () => import('../views/why-join-commsec/choosing-an-online-broker.vue'),
      },
      {
        path: 'making-the-switch-to-commsec',
        name: 'makingTheSwitchToCommsec',
        component: () => import('../views/why-join-commsec/making-the-switch-to-commsec.vue'),
      },
      {
        path: 'commsec-one',
        name: 'commsecOne',
        component: () => import('../views/why-join-commsec/commsec-one.vue'),
      },
    ]
  },
  {
    path: '/accounts',
    name: 'accounts',
    children: [
      {
        path: 'share-trading',
        name: 'shareTrading',
        component: () => import('../views/accounts/share-trading.vue'),
      },
      {
        path: 'margin-lending',
        name: 'marginLending',
        component: () => import('../views/accounts/margin-lending.vue'),
      },
      {
        path: 'eto-options-account',
        name: 'etoOptionsAccount',
        component: () => import('../views/accounts/eto-options-account.vue'),
      },
      {
        path: 'international-shares',
        name: 'internationalShares',
        component: () => import('../views/accounts/international-shares.vue'),
      },
    ]
  },
  {
    path: '/products',
    name: 'products',
    children: [
      {
        path: 'australian-share-trading',
        name: 'australianShareTrading',
        component: () => import('../views/products/australian-share-trading.vue'),
      },
      {
        path: 'margin-loan',
        name: 'marginLoan',
        component: () => import('../views/products/margin-loan.vue'),
      },
      {
        path: 'cash-investment-account',
        name: 'cashInvestmentAccount',
        component: () => import('../views/products/cash-investment-account.vue'),
      },
      {
        path: 'eto-options',
        name: 'etoOptions',
        component: () => import('../views/products/eto-options.vue'),
      },
      {
        path: 'exchange-traded-funds',
        name: 'exchangeTradedFunds',
        component: () => import('../views/products/exchange-traded-funds.vue'),
      },
      {
        path: 'warrants',
        name: 'warrants',
        component: () => import('../views/products/warrants.vue'),
      },
      {
        path: 'fixed-income-securities',
        name: 'fixedIncomeSecurities',
        component: () => import('../views/products/fixed-income-securities.vue'),
      },
      {
        path: 'pocket',
        name: 'pocket',
        component: () => import('../views/products/pocket.vue'),
      },
      {
        path: 'international-shares',
        name: 'internationalShares',
        component: () => import('../views/products/international-shares.vue'),
      },
      {
        path: 'minor-trust-accounts',
        name: 'minorTrustAccounts',
        component: () => import('../views/products/minor-trust-accounts.vue'),
      },
    ]
  },
  {
    path: '/support',
    name: 'support',
    children: [
      {
        path: 'contact-us',
        name: 'contactUs',
        component: () => import('../views/support/contact-us.vue'),
      },
      {
        path: 'forms-and-brochures',
        name: 'formsAndBrochures',
        component: () => import('../views/support/forms-and-brochures.vue'),
      },
      {
        path: 'your-feedback',
        name: 'yourFeedback',
        component: () => import('../views/support/your-feedback.vue'),
      },
      {
        path: 'rates-and-fees',
        name: 'ratesAndFees',
        component: () => import('../views/support/rates-and-fees.vue'),
      },
      {
        path: 'help-centre',
        name: 'helpCentre',
        component: () => import('../views/support/help-centre.vue'),
      },
      {
        path: 'Australian-Share-Transfer-Hub',
        name: 'australianShareTransferHub',
        component: () => import('../views/support/Australian-Share-Transfer-Hub.vue'),
      },
    ]
  },
  {
    path: '/market-news',
    name: 'marketNews',
    children: [
      {
        path: 'the-markets',
        name: 'theMarkets',
        component: () => import('../views/market-news/the-markets.vue'),
      },
      {
        path: 'executive-series',
        name: 'executiveSeries',
        component: () => import('../views/market-news/executive-series.vue'),
      },
      {
        path: 'year-ahead',
        name: 'yearAhead',
        component: () => import('../views/market-news/year-ahead.vue'),
      },
      {
        path: 'reporting-season',
        name: 'reportingSeason',
        component: () => import('../views/market-news/reporting-season.vue'),
      },
      {
        path: 'podcast',
        name: 'podcast',
        component: () => import('../views/market-news/podcast.vue'),
      },
    ]
  },
  {
    path: '/education',
    name: 'education',
    children: [
      {
        path: 'under-30s',
        name: 'under30s',
        component: () => import('../views/education/under-30s.vue'),
      },
      {
        path: 'commsec-learn',
        name: 'commsecLearn',
        component: () => import('../views/education/commsec-learn.vue'),
      },
      {
        path: 'learn',
        name: 'learn',
        component: () => import('../views/education/learn.vue'),
      },
      {
        path: 'webinars',
        name: 'webinars',
        component: () => import('../views/education/webinars.vue'),
      },
      {
        path: 'Invest_podcast',
        name: 'InvestPodcast',
        component: () => import('../views/education/Invest_podcast.vue'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

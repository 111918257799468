<template>
  <div class="wrapper ng-scope" :class="{ 'reveal-left': isExpand }">
    <page-left></page-left>
    <div>
      <header-layout @expand="isExpand = !isExpand"></header-layout>
      <router-view></router-view>
      <div class="module-overlay" @click="isExpand = !isExpand"></div>
      <footer-layout></footer-layout>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import HeaderLayout from '@/components/HeaderLayout'
import FooterLayout from '@/components/FooterLayout'
import PageLeft from '@/components/page-left'

const isExpand = ref(false)
</script>

<style></style>
<template>

  <!-- BEGIN global-nav MOBILE -->
  <section class="global-nav visible-xs" data-spy="affix" style="">
    <ul class="top-nav list-inline">
      <li>
        <a href="#" class="hamburger" @click="$emit('expand')"></a>
      </li>
      <li class="container-logo">
        <a href="/"><img src="/images/logo_black.png" alt="Commsec Pro"></a>
      </li>
      <li>
        <div class="dropdownmob">
          <a class="dropbtnmob" onclick="myFunction()"> Join </a>
          <div id="dropdownMob" class="dropdown-content-mob">
            <a style="border-bottom:1px solid;"
              href="/products/australian-share-trading.html?icid=Join_Now_Button-pro-unt-acq-aco-pulib1-20211124-B#get-started-with-share-trading">Share
              Trading</a>
            <a style="border-bottom:1px solid;"
              href="/products/pocket.html?icid=Join_Now_Button-pro-unt-acq-aco-pulib1-20211124-B#getstarted">Pocket</a>
            <a style="border-bottom:1px solid;"
              href="/products/margin-loan.html?icid=Join_Now_Button-pro-unt-acq-aco-pulib1-20211124-B#get-started-with-a-margin-loan">Margin
              Lending</a>
            <a style="border-bottom:1px solid;"
              href="/products/eto-options.html?icid=Join_Now_Button-pro-unt-acq-aco-pulib1-20211124-B#get-started-with-eto">Options
              Trading</a>
            <a
              href="/products/international-shares.html?icid=Join_Now_Button-pro-unt-acq-aco-pulib1-20211124-B#get-started-with-international-share-trading-account">International
              Stock Trading</a>
          </div>
        </div>
      </li>
      <li>
        <a href="#" class="nav-search"></a>
      </li>
      <li>
        <a id="mobile-login-button" href="#" class="nav-login"></a>
      </li>
    </ul>
    <form class="search container ng-pristine ng-valid" action="/services/support/content/search/Search">
      <input type="text" placeholder="Enter search word" name="q">
      <button></button>
      <div class="module-overlay"></div>
    </form>
  </section>
  <!-- END global-nav MOBILE -->

  <section class="login hidden-xs">
    <!--[if lt IE 9]>
        <div id="browser-version" class="ie8"></div>
        <![endif]-->
    <div class="container">
      <a href="/homepage.html" class="logo pull-left"><img
          src="/content/dam/EN/Images/CommSec%20Public/CommSec%20brandmarks/CommSec_Diamond_35x35.png"
          alt="Commsec Pro"></a>
    </div>
  </section>

  <!-- BEGIN global-nav -->
  <div class="global-nav-wrapper affix-top" data-spy="affix">
    <section data-ng-controller="MegaNavController" class="ng-scope">
      <div class="inner global-nav hidden-xs">
        <div class="container">
          <ul class="nav">
            <li class="logo">
              <a href="/"><img src="/images/logo_black.png" alt="Commsec Pro"></a>
            </li>
            <!-- ngRepeat: navitem in nav -->
            <li data-ng-repeat="navitem in nav"
              data-ng-class="{'active-load': activeClass(navitem.title, 'data-nav-parent'), 'active active-touch': $index == activeIndex}"
              data-ng-click="toggleActive($index)" class="ng-scope">
              <span data-ng-bind="navitem.title" data-ng-show="navitem.subCols" class="ng-binding">Why Commsec Pro</span>
              <!-- <a href="/why-join-commsec.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.title"
                data-ng-show="!navitem.subCols" data-ng-click="goToPage(navitem.link)">Why Commsec Pro</a> -->
              <div class="mega-sub-container" data-ng-show="navitem.subCols">
                <div class="container">
                  <div class="css-table">
                    <!-- ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding" v-if="false">  
                        <p><b><span class="color-yellow-header-h3">Australia's leading online broker</span></b></p>
                        <p><span class="color-white">With&nbsp;</span><b><span class="color-yellow">20 years of industry
                              leading service and experience</span></b><span class="color-white">, offering Australia's
                            best online and mobile trading solutions Commsec Pro is the first choice when considering retail
                            online trading partners.</span><br>
                        </p>
                      </span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/why-join-commsec/learn-about-the-markets" data-ng-bind-html="childPage.title"
                              class="ng-binding">Help me understand the Stock
                              market</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Understanding how it all works is the first step to investing in stocks.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/why-join-commsec/choosing-an-online-broker" data-ng-bind-html="childPage.title"
                              class="ng-binding">Choose an online Stock broker</a>
                          </h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>See why Commsec Pro is the first choice in online brokers.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}" v-if="false">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/why-join-commsec/making-the-switch-to-commsec"
                              data-ng-bind-html="childPage.title" class="ng-binding">Why make the switch to Commsec Pro</a>
                          </h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>See the value in moving to Commsec Pro.<br>
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/why-join-commsec/commsec-one" data-ng-bind-html="childPage.title"
                              class="ng-binding">Commsec Pro One</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>The active trader program that gives personalised support, priority access and
                                exclusive offers.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope last" data-ng-repeat="child in navitem.subCols"
                      data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                  </div>
                </div>
                <a href="#" class="btn-close" data-ng-click="toggleActive(-1 , $event)"><span>CLOSE</span></a>
              </div>
            </li><!-- end ngRepeat: navitem in nav -->
            <li data-ng-repeat="navitem in nav" v-if="false"
              data-ng-class="{'active-load': activeClass(navitem.title, 'data-nav-parent'), 'active active-touch': $index == activeIndex}"
              data-ng-click="toggleActive($index)" class="ng-scope">
              <span data-ng-bind="navitem.title" data-ng-show="navitem.subCols" class="ng-binding">Join Now</span>
              <div class="mega-sub-container" data-ng-show="navitem.subCols">
                <div class="container">
                  <div class="css-table">
                    <!-- ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding">
                        <p><b><span class="color-yellow-header-h3">Join now</span></b></p>
                        <p><span class="color-white">It's</span><b> <span class="color-yellow"> free to join </span></b>
                          <span class="color-white">and there are</span><b> <span class="color-yellow"> no monthly
                              account fees</span></b>. <span class="color-white">Simply select the account that suits
                            you best and you could be trading with Commsec Pro in as little as 5 minutes.</span><br>
                        </p>
                      </span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/accounts/share-trading" data-ng-bind-html="childPage.title"
                              class="ng-binding">Australian & U.S. Stock Trading</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>With a Commsec Pro Australian & U.S. Stock Trading Account, you've got everything you need to
                                invest with confidence.&nbsp;</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/accounts/margin-lending" data-ng-bind-html="childPage.title"
                              class="ng-binding">Margin Lending</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Borrowing to invest with a Commsec Pro Margin Loan gives you automatic access to our
                                award-winning trading platform.&nbsp;</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/accounts/eto-options-account" data-ng-bind-html="childPage.title"
                              class="ng-binding">Options Trading</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>You can trade Options just as easily as you trade stocks.&nbsp;</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/accounts/international-shares" data-ng-bind-html="childPage.title"
                              class="ng-binding">International Stock Trading</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>A Commsec Pro International stocks Account gives you access to global Stock markets.<br>
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope last" data-ng-repeat="child in navitem.subCols"
                      data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                  </div>
                </div>
                <a href="#" class="btn-close" data-ng-click="toggleActive(-1 , $event)"><span>CLOSE</span></a>
              </div>
            </li><!-- end ngRepeat: navitem in nav -->
            <li data-ng-repeat="navitem in nav" v-if="false"
              data-ng-class="{'active-load': activeClass(navitem.title, 'data-nav-parent'), 'active active-touch': $index == activeIndex}"
              data-ng-click="toggleActive($index)" class="ng-scope">
              <span data-ng-bind="navitem.title" data-ng-show="navitem.subCols" class="ng-binding">Products</span>
              <!-- <a href="/products.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.title"
                data-ng-show="!navitem.subCols" data-ng-click="goToPage(navitem.link)">Products</a> -->
              <div class="mega-sub-container" data-ng-show="navitem.subCols">
                <div class="container">
                  <div class="css-table">
                    <!-- ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding">
                        <p><span><b><span class="color-yellow-header-h3">The ability to grow</span></b></span></p>
                        <p><span class="color-white">We offer a wide range of products so that as you are ready to
                            become more sophisticated in your trading needs, we are ready to partner with you on your
                            journey.</span></p>
                      </span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/australian-share-trading" data-ng-bind-html="childPage.title"
                              class="ng-binding">Australian and U.S. Stock</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Trading stocks is affordable, simple and flexible.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/margin-loan" data-ng-bind-html="childPage.title"
                              class="ng-binding">Margin Loan</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Use this powerful investment tool to expand your portfolio with borrowed funds.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/cash-investment-account" data-ng-bind-html="childPage.title"
                              class="ng-binding">Cash</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>With a linked CDIA you can seamlessly settle trades, transact and earn interest.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/eto-options" data-ng-bind-html="childPage.title"
                              class="ng-binding">Options</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Take advantage of leverage opportunities.<br>
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/exchange-traded-funds" data-ng-bind-html="childPage.title"
                              class="ng-binding">Exchange Traded Funds (ETFs)</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>One simple ETF transaction can help you diversify your portfolio.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/warrants" data-ng-bind-html="childPage.title"
                              class="ng-binding">Warrants</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Offer the potential to diversify your investments and spread risk across your
                                portfolio.<br>
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/fixed-income-securities" data-ng-bind-html="childPage.title"
                              class="ng-binding">Fixed Income Securities</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Opportunity to provide regular income and diversify your portfolio.<br>
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/pocket" data-ng-bind-html="childPage.title" class="ng-binding">Commsec Pro
                              Pocket</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Invest anytime, anywhere with as little as $50 into themed options.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope last" data-ng-repeat="child in navitem.subCols"
                      data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/international-shares" data-ng-bind-html="childPage.title"
                              class="ng-binding">International Stocks</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Gain access to global Stock markets.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/products/minor-trust-accounts" data-ng-bind-html="childPage.title"
                              class="ng-binding">Invest for your kids</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Investing on behalf of your children using a Commsec Pro Minor Trust Account can give them
                                a head start on their financial future.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                  </div>
                </div>
                <a href="#" class="btn-close" data-ng-click="toggleActive(-1 , $event)"><span>CLOSE</span></a>
              </div>
            </li><!-- end ngRepeat: navitem in nav -->
            <li data-ng-repeat="navitem in nav" v-if="false"
              data-ng-class="{'active-load': activeClass(navitem.title, 'data-nav-parent'), 'active active-touch': $index == activeIndex}"
              data-ng-click="toggleActive($index)" class="ng-scope">
              <span data-ng-bind="navitem.title" data-ng-show="navitem.subCols" class="ng-binding">Support</span>
              <!-- <a href="/support.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.title"
                data-ng-show="!navitem.subCols" data-ng-click="goToPage(navitem.link)">Support</a> -->
              <div class="mega-sub-container" data-ng-show="navitem.subCols">
                <div class="container">
                  <div class="css-table">
                    <!-- ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding">
                        <p><b><span class="color-yellow-header-h3">Need help?</span></b></p>
                        <p><span class="color-white">Contact us via one of the channels below, take a look at our FAQs
                            or login and chat to others, just like you, in Commsec Pro Community.</span></p>
                        <p><span class="color-white"><b>Twitter: &nbsp; &nbsp; &nbsp; &nbsp;</b></span><a
                            href="https://twitter.com/Commsec Pro" target="_blank"><span
                              class="color-yellow">@Commsec Pro</span></a></p>
                        <p><span><span class="color-white"><b>Online: &nbsp; &nbsp; &nbsp; &nbsp;</b></span></span><a
                            href="/content/commsec/en/support/contact-us.html"><span class="color-yellow">Contact
                              Us</span></a></p>
                        <p><b><span class="color-white">Phone: &nbsp; &nbsp; &nbsp; &nbsp;</span></b>13 15 19</p>
                        <p><span class="color-white-header-h6">All channels serviced:</span></p>
                        <p><span class="color-white-header-h6">Mon - Fri 8am-7pm (Sydney time)</span></p>
                      </span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href=".html" data-ng-bind-html="childPage.title" class="ng-binding"></a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding"></span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/support/contact-us" data-ng-bind-html="childPage.title"
                              class="ng-binding">Contact-Us</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Have a question? Need help? Contact us via our online form.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/support/forms-and-brochures" data-ng-bind-html="childPage.title"
                              class="ng-binding">Forms &amp; Brochures</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Find out more about our products or to apply for new products with our forms and
                                brochures.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href=".html" data-ng-bind-html="childPage.title" class="ng-binding"></a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding"></span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/support/your-feedback" data-ng-bind-html="childPage.title"
                              class="ng-binding">Your Feedback</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Let us know what you think about our products, services and solutions.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/support/rates-and-fees" data-ng-bind-html="childPage.title"
                              class="ng-binding">Rates &amp; Fees</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>For a breakdown of Commsec Pro's fees and charges by product.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope last" data-ng-repeat="child in navitem.subCols"
                      data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/support/help-centre" data-ng-bind-html="childPage.title" class="ng-binding">Help
                              Centre</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Get help with managing your account, placing a trade, transferring stocks, and more.
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <!-- 隐藏 -->
                        <li style="display:none;" data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/support/Australian-Share-Transfer-Hub" data-ng-bind-html="childPage.title"
                              class="ng-binding">Australian Stock Transfer Hub</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Commsec Pro's go-to-guide on transferring Australian and U.S. Stock.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                  </div>
                </div>
                <a href="#" class="btn-close" data-ng-click="toggleActive(-1 , $event)"><span>CLOSE</span></a>
              </div>
            </li><!-- end ngRepeat: navitem in nav -->
            <li data-ng-repeat="navitem in nav"
              data-ng-class="{'active-load': activeClass(navitem.title, 'data-nav-parent'), 'active active-touch': $index == activeIndex}"
              data-ng-click="toggleActive($index)" class="ng-scope">
              <span data-ng-bind="navitem.title" data-ng-show="navitem.subCols" class="ng-binding">Market News</span>
              <!-- <a href="/market-news.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.title"
                data-ng-show="!navitem.subCols" data-ng-click="goToPage(navitem.link)">Market News</a> -->
              <div class="mega-sub-container" data-ng-show="navitem.subCols">
                <div class="container">
                  <div class="css-table">
                    <!-- ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding">
                        <p><span class="color-yellow-header-h3">Market insights to help you trade</span></p>
                        <p><span class="color-white">Insights and observations from the leading team at
                            Commsec Pro.</span><br>
                        </p>
                      </span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}" v-if="false">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope" v-if="false">
                          <h4><a href="/market-news/the-markets" data-ng-bind-html="childPage.title"
                              class="ng-binding">The Markets</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p><span class="color-white">Find out what’s moving markets with our daily
                                  coverage.</span></p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope" v-if="false">
                          <h4><a href="/market-news/executive-series" data-ng-bind-html="childPage.title"
                              class="ng-binding">Executive Series</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p><span class="color-white">We interview leaders from a range of ASX-listed companies to
                                  help you uncover potential opportunities.</span></p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/market-news/executive-series" data-ng-bind-html="childPage.title"
                              class="ng-binding">Executive Series</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p><span class="color-white">We interview leaders from a range of ASX-listed companies to
                                  help you uncover potential opportunities.</span></p>
                            </span></p>
                        </li>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/market-news/year-ahead" data-ng-bind-html="childPage.title"
                              class="ng-binding">The Year Ahead</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>The factors likely to impact financial markets over 2024.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope" v-if="false">
                          <h4><a href="/market-news/reporting-season" data-ng-bind-html="childPage.title"
                              class="ng-binding">Reporting Season</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Don’t miss a beat. View our comprehensive earnings coverage every February and August.
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope last" data-ng-repeat="child in navitem.subCols"
                      data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope" v-if="false">
                          <h4><a href=".html" data-ng-bind-html="childPage.title" class="ng-binding"></a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding"></span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope" v-if="false">
                          <h4><a href="/market-news/podcast" data-ng-bind-html="childPage.title"
                              class="ng-binding">Commsec Pro Market Update podcast</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Understand key market movements and why they matter with twice-daily updates from
                                Australia’s leading market experts.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                  </div>
                </div>
                <a href="#" class="btn-close" data-ng-click="toggleActive(-1 , $event)"><span>CLOSE</span></a>
              </div>
            </li><!-- end ngRepeat: navitem in nav -->
            <!-- 隐藏 -->
            <li data-ng-repeat="navitem in nav" style="display:none;"
              data-ng-class="{'active-load': activeClass(navitem.title, 'data-nav-parent'), 'active active-touch': $index == activeIndex}"
              data-ng-click="toggleActive($index)" class="ng-scope">
              <span data-ng-bind="navitem.title" data-ng-show="navitem.subCols" class="ng-binding">Education</span>
              <!-- <a href="/education.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.title"
                data-ng-show="!navitem.subCols" data-ng-click="goToPage(navitem.link)">Education</a> -->
              <div class="mega-sub-container" data-ng-show="navitem.subCols">
                <div class="container">
                  <div class="css-table">
                    <!-- ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding">
                        <h3><span class="color-yellow">Boost your investing knowledge</span></h3>
                        <p><span class="color-white">Refine your strategy with in-depth articles and videos from the
                            experts.</span><br>
                        </p>
                      </span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/education/under-30s" data-ng-bind-html="childPage.title"
                              class="ng-binding">Commsec Pro Stock’d</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Fully stock’d with all the support, information and resources you need to build your
                                confidence and make the right money moves.&nbsp;</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/education/commsec-learn" data-ng-bind-html="childPage.title"
                              class="ng-binding">Commsec Pro Learn</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Whatever your level of investment experience, there’s always more to learn. Commsec Pro
                                Learn is designed to help you do just that.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope" data-ng-repeat="child in navitem.subCols" data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/education/learn" data-ng-bind-html="childPage.title" class="ng-binding">New
                              to Commsec Pro</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Everything you need to get started in the one place. Best if you’ve recently joined us.
                              </p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/education/webinars" data-ng-bind-html="childPage.title"
                              class="ng-binding">Webinars</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>Browse our comprehensive suite of webinars, with advanced ideas from industry leaders
                                and our expert team at Commsec Pro.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                    <div class="col ng-scope last" data-ng-repeat="child in navitem.subCols"
                      data-ng-class="{last: $last}">
                      <span data-ng-bind-html="child.overview" class="ng-binding"></span>
                      <ul>
                        <!-- ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href=".html" data-ng-bind-html="childPage.title" class="ng-binding"></a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding"></span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                        <li data-ng-repeat="childPage in child.subItems" class="ng-scope">
                          <h4><a href="/education/Invest_podcast" data-ng-bind-html="childPage.title"
                              class="ng-binding">Commsec Pro Invest podcast</a></h4>
                          <p><span data-ng-bind-html="childPage.description" class="ng-binding">
                              <p>We breakdown the world of Stock market investing in our Commsec Pro Invest Podcast.</p>
                            </span></p>
                        </li><!-- end ngRepeat: childPage in child.subItems -->
                      </ul>
                      <p data-ng-show="child.featureImage" class="ng-hide"><a href=""><img data-ng-src="" alt=""></a>
                      </p>
                    </div><!-- end ngRepeat: child in navitem.subCols -->
                  </div>
                </div>
                <a href="#" class="btn-close" data-ng-click="toggleActive(-1 , $event)"><span>CLOSE</span></a>
              </div>
            </li><!-- end ngRepeat: navitem in nav -->
            <li class="nav-search">
              <form action="/services/support/content/search/Search" class="ng-pristine ng-valid">
                <div class="input-group">
                  <input id="nav_search_input" name="q" type="text" placeholder="Search">
                  <button class="search-submit" type="submit"></button>
                </div>
              </form>
            </li>
            <li class="nav-login-button">

              <button type="button" class="login--one-click-login" id="login">
                Login
              </button>

            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
  <!-- END global-nav -->
</template>

<script setup>
</script>

<style scoped>
.global-nav {
  border-bottom: 1px solid #acacac !important;
}

.mega-sub-container {
  border-bottom: 1px solid #acacac !important;
}

.login--one-click-login {
  font-family: 'cba_beaconsans-regular' !important;
}

.mega-sub-container .col {
  border-left: 0px !important;
  border-right: 1px solid #cacaca;
}

.global-nav .container {
  font-family: 'cba_beaconsans-regular';
  margin-left: auto;
  margin-right: auto;
  max-width: 1750px;
  color: black !important;
  background-color: white !important;
}

.nav {
  margin-bottom: 0px !important;
}

.nav>li>a>img {
  height: 70px !important;
  max-width: none !important;
  /*margin-left: auto;
      margin-right: auto;*/
}

.global-nav {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}

.global-nav .nav>li {
  border-left: 0px;
  border-right: 0px;
  width: 220px !important;
  background-color: white;
  color: black;
}

.global-nav .nav>li.active-load {
  background: none;
  border-bottom: 3px solid #fc0 !important;
}

.global-nav .nav>li:hover {
  background: #ffffff !important;
  color: black !important;
}

.global-nav .mega-sub-container {
  background: #ffffff !important;
  top: 83px !important;
}

.global-nav .mega-sub-container .container {
  max-width: 1500px !important;
  padding-left: 300px;
}

.global-nav .color-yellow {
  color: #000000 !important;
}

.global-nav .color-white {
  color: #000000 !important;
}

.global-nav .color-yellow-header-h3 {
  color: #000000 !important;
  font-weight: bold;
}

.global-nav h3>.color-yellow {
  font-weight: bold;
}

.global-nav .nav>li:not(.logo):not(.nav-search):not(.nav-login-button):hover {
  border-bottom: 3px solid #fc0 !important;
}

.mega-sub-container h4>a {
  text-decoration: none;
  font-weight: bold;
  color: #000000 !important;
}

.mega-sub-container h4>a:hover {
  text-decoration: none !important;
  color: #000000 !important;
  border-bottom: 2px solid #fc0 !important;
}


.login {
  background: linear-gradient(to bottom, #231f20 0%, #231f20 100%) !important;
  border-bottom: 1px solid #231f20 !important;
}

.login>.container {
  max-width: 1750px !important;
}

.global-nav input[type="text"] {
  color: #000000 !important;
  width: 100% !important;
}

.global-nav.reveal-search .nav-search input[type="text"] {
  min-width: 250px !important;
}

.global-nav .nav-search .search-submit {
  width: 50px;
  height: 19px;
  background: url("@/assets/images/sprite-icons.png") no-repeat -35px -45px !important;
  border: none;
  position: absolute;
  top: 9px !important;
  right: -22px !important;
}

/*Mobile Navigation Styles*/
.mob-global-nav {
  font-family: 'cba_beaconsans-regular';
}

.global-nav .top-nav {
  background: #FFFFFF !important;
}

.global-nav .top-nav li {
  border-right: 0px !important;
  border-left: 0px !important;
}

.global-nav .top-nav .container-logo {
  height: 70px !important;
  margin-left: auto;
  margin-right: auto;
}

.global-nav .top-nav li.container-logo img {
  max-height: none;
  height: 60px;
}

.global-nav a.nav-search:after {
  width: 15px !important;
  height: 14px;
  background-position: -35px -50px ! important;
}

.global-nav a.nav-login:after {
  background-position: -89px -75px !important;
}

.global-nav .top-nav li:nth-child(3) {
  border-left: 0.1px solid #cacaca !important;
  border-right: 0.1px solid #cacaca !important;
  /* border-bottom: 0.1px solid #cacaca !important;*/
  /* border-top: 0.1px solid #cacaca !important; */
}

.global-nav .top-nav li:nth-child(4) {
  /*border-left: 0.1px solid #cacaca !important;*/
  border-right: 0.1px solid #cacaca !important;
  /*  border-bottom: 0.1px solid #cacaca !important; */
  /* border-top: 0.1px solid #cacaca !important; */
}

.mob-global-nav .nav li a {
  background-color: #fff !important;
  color: #000 !important;
  border-bottom: 1px solid #cacaca;
  border-right: 1px solid #cacaca;
}

.mob-global-nav .nav>li>a:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 14px;
  height: 8px;
  right: 20px;
  top: 22px;
  background: url('@/assets/images/sprite-icons.png') no-repeat -21px -77px !important;
}
</style>
<template>
  <div class="page-left">
    <!-- Although the path for this cq:include is relative, it doesn't actually store any data. The internal component
            looks up an absolute path to the data. We have to use a different path than the desktop navigation reference as
            CQ can't have 2 components share the same path (as they are different components) -->
    <!-- ANGULAR JS NAV IMPLEMENTATION -->
    <!-- BEGIN global-nav -->
    <section class="mob-global-nav collapsible-links ng-scope" data-ng-controller="SiteNavController">
      <ul class="nav list-inline">
        <!-- ngRepeat: navitem in rawnav -->
        <li data-ng-repeat="navitem in rawnav"
          data-ng-class="{'active active-page': activeClass(navitem.pageInfo.jcrTitle, 'data-nav-parent')}"
          class="ng-scope">
          <!-- <a href="/why-join-commsec.html" class="no-children ng-binding ng-hide"
            data-ng-bind="navitem.pageInfo.jcrTitle" data-ng-show="navitem.childPages.length == 0">Why Commsec Pro</a> -->
          <a data-ng-bind="navitem.pageInfo.jcrTitle" @click="isExpand1 = !isExpand1"
            data-ng-show="navitem.childPages.length > 0" class="ng-binding">Why Commsec Pro</a>
          <ul :style="isExpand1 ? 'display: block;' : 'display: none;'">
            <!-- ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/why-join-commsec/learn-about-the-markets" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Help me understand the share market</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/why-join-commsec/choosing-an-online-broker" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Choose an online share broker</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/why-join-commsec/making-the-switch-to-commsec" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Why make the switch to Commsec Pro</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/why-join-commsec/commsec-one" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Commsec Pro One</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <!-- ngRepeat: child in navitem.subCols -->
          </ul>
        </li><!-- end ngRepeat: navitem in rawnav -->
        <li data-ng-repeat="navitem in rawnav" v-if="false"
          data-ng-class="{'active active-page': activeClass(navitem.pageInfo.jcrTitle, 'data-nav-parent')}"
          class="ng-scope">
          
          <a data-ng-bind="navitem.pageInfo.jcrTitle" data-ng-show="navitem.childPages.length > 0" class="ng-binding"
            @click="isExpand2 = !isExpand2">Join Now</a>
          <ul :style="isExpand2 ? 'display: block;' : 'display: none;'">
            <!-- ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/accounts/share-trading" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Australian & U.S. Share Trading</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/accounts/margin-lending" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Margin
                Lending</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/accounts/eto-options-account" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Options Trading</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/accounts/international-share-trading" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">International Share Trading</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <!-- ngRepeat: child in navitem.subCols -->
          </ul>
        </li><!-- end ngRepeat: navitem in rawnav -->
        <li data-ng-repeat="navitem in rawnav" v-if="false"
          data-ng-class="{'active active-page': activeClass(navitem.pageInfo.jcrTitle, 'data-nav-parent')}"
          class="ng-scope">
          <!-- <a href="/products.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.pageInfo.jcrTitle"
            data-ng-show="navitem.childPages.length == 0">Products</a> -->
          <a data-ng-bind="navitem.pageInfo.jcrTitle" data-ng-show="navitem.childPages.length > 0" class="ng-binding"
            @click="isExpand3 = !isExpand3">Products</a>
          <ul :style="isExpand3 ? 'display: block;' : 'display: none;'">
            <!-- ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/australian-share-trading" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Australian Shares</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/margin-loan" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Margin
                Loan</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/cash-investment-account" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Cash</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/eto-options" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Options</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/exchange-traded-funds" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Exchange Traded Funds (ETFs)</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/warrants" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Warrants</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/fixed-income-securities" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Fixed Income Securities</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/pocket" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Commsec Pro
                Pocket</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/international-shares" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">International Shares</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/products/minor-trust-accounts" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Invest for your kids</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <!-- ngRepeat: child in navitem.subCols -->
          </ul>
        </li><!-- end ngRepeat: navitem in rawnav -->
        <li data-ng-repeat="navitem in rawnav" v-if="false"
          data-ng-class="{'active active-page': activeClass(navitem.pageInfo.jcrTitle, 'data-nav-parent')}"
          class="ng-scope">
          <!-- <a href="/support.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.pageInfo.jcrTitle"
            data-ng-show="navitem.childPages.length == 0">Support</a> -->
          <a data-ng-bind="navitem.pageInfo.jcrTitle" data-ng-show="navitem.childPages.length > 0" class="ng-binding"
            @click="isExpand4 = !isExpand4">Support</a>
          <ul :style="isExpand4 ? 'display: block;' : 'display: none;'">
            <!-- ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/support/contact-us" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Contact-Us</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/support/forms-and-brochures" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Forms &amp; Brochures</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/support/your-feedback" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Your
                Feedback</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/support/rates-and-fees" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Rates
                &amp; Fees</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/support/help-centre" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Help
                Centre</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/support/Australian-Share-Transfer-Hub" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Australian Share Transfer Hub</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <!-- ngRepeat: child in navitem.subCols -->
          </ul>
        </li><!-- end ngRepeat: navitem in rawnav -->
        <li data-ng-repeat="navitem in rawnav"
          data-ng-class="{'active active-page': activeClass(navitem.pageInfo.jcrTitle, 'data-nav-parent')}"
          class="ng-scope">
          <!-- <a href="/market-news.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.pageInfo.jcrTitle"
            data-ng-show="navitem.childPages.length == 0">Market News</a> -->
          <a data-ng-bind="navitem.pageInfo.jcrTitle" data-ng-show="navitem.childPages.length > 0" class="ng-binding"
            @click="isExpand5 = !isExpand5">Market News</a>
          <ul :style="isExpand5 ? 'display: block;' : 'display: none;'">
            <!-- ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/market-news/the-markets" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">The
                Markets</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/market-news/executive-series" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Executive Series</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/market-news/year-ahead" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">The
                Year Ahead</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/market-news/reporting-season" data-ng-bind-html="child.pageInfo.jcrTitle"
                class="ng-binding">Reporting Season</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/market-news/podcast" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Commsec Pro
                Market Update podcast</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <!-- ngRepeat: child in navitem.subCols -->
          </ul>
        </li><!-- end ngRepeat: navitem in rawnav -->
        <li data-ng-repeat="navitem in rawnav" v-if="false"
          data-ng-class="{'active active-page': activeClass(navitem.pageInfo.jcrTitle, 'data-nav-parent')}"
          class="ng-scope">
          <!-- <a href="/education.html" class="no-children ng-binding ng-hide" data-ng-bind="navitem.pageInfo.jcrTitle"
            data-ng-show="navitem.childPages.length == 0">Education</a> -->
          <a data-ng-bind="navitem.pageInfo.jcrTitle" data-ng-show="navitem.childPages.length > 0" class="ng-binding"
            @click="isExpand6 = !isExpand6">Education</a>
          <ul :style="isExpand6 ? 'display: block;' : 'display: none;'">
            <!-- ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/education/under-30s" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Commsec Pro
                Stock’d</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/education/commsec-learn" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Commsec Pro
                Learn</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/education/learn" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">New to
                Commsec Pro</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/education/webinars" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Webinars</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <li data-ng-repeat="child in navitem.childPages"
              data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope">
              <a href="/education/Invest_podcast" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Commsec Pro
                Invest podcast</a>
            </li><!-- end ngRepeat: child in navitem.childPages -->
            <!-- ngRepeat: child in navitem.subCols -->
          </ul>
        </li><!-- end ngRepeat: navitem in rawnav -->
      </ul>
    </section>
    <!-- END global-nav -->
    <!-- END ANGULAR JS NAV IMPLEMENTATION -->
  </div>
</template>

<script setup>
import { ref } from 'vue'

const isExpand1 = ref(false)
const isExpand2 = ref(false)
const isExpand3 = ref(false)
const isExpand4 = ref(false)
const isExpand5 = ref(false)
const isExpand6 = ref(false)
</script>
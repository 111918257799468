import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// import '@/assets/css/page.css'

// import 'jquery'
// import 'popper.js'
// import 'bootstrap/dist/js/bootstrap.min.js'
// import 'bootstrap/dist/css/bootstrap.css'

createApp(App).use(router).mount('#app')

<template>
  <footer id="footer" class="black-bg">
    <div class="dark-grey-bg">
      <div class="container">
        <div class="row  footer-container">
          <div class="col-xs-12 col-sm-3 col-md-2 footer-logo">
            <img src="/images/CommSec_120x42.png" alt="Commsec Pro" style="width:100%">
          </div>
          <div class="col-xs-12 col-sm-9 col-md-6 footer-links">
            <ul>
              <li>
                <a href="/features/important-information.html" target="_self">Important Information</a>
              </li>
              <li>
                <a href="/content/dam/EN/PDFs/FSG/fsg.pdf" target="_blank">Financial Services Guide</a>
              </li>
              <li>
                <a href="#">Privacy Statement</a>
              </li>
              <li>
                <a href="https://commsec.com.au/support/help-centre/managing-your-payments/financial-difficulty.html"
                  target="_blank">Financial Assistance</a>
              </li>

            </ul>
            <div class="clearfix"></div>
          </div>
          <div class="clearfix visible-xs visible-sm"></div>

        </div>
        <div class="row dark-grey-bg footer-container">
          <div class="footer-list footer-download">
            <div class="col-xs-12 col-sm-3 col-md-2">
              <h2><strong>Download the Commsec Pro App</strong> </h2>
            </div>
            <div class="col-xs-12 col-sm-9 col-md-10">
              <ul>
                <li>
                  <a id="iosAppDownload" style="cursor:pointer;"><img src="/images/Apple-app-store_116x35.png"></a>
                </li>
                <li>
                  <a id="androidAppDownload" style="cursor:pointer;"><img src="/images/google-play_99x35.png"></a>
                </li>

              </ul>
            </div>
            <div class="clearfix"></div>
          </div>
          <div class="footer-list footer-download">
            <div class="col-xs-12 col-sm-3 col-md-2">
              <h2></h2>
            </div>
            <div class="col-xs-12 col-sm-3 col-md-10">
              <ul>

              </ul>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row  footer-index-links collapsible-links footer-xs-btm  footer-container"  v-if="false">
        <ul data-ng-controller="SiteNavController" class="ng-scope">
          <!-- ngRepeat: item in rawnav -->
          <li class="col-md-2 col-sm-4 col-xs-12 ng-scope active-page active" data-ng-repeat="item in rawnav"
            data-ng-class="{'active-page active': activeClass(item.pageInfo.jcrTitle, 'data-nav-parent')}">
            <a href="#" data-ng-bind-html="item.pageInfo.jcrTitle" data-ng-show="item.childPages.length > 0"
              class="ng-binding">Why Commsec Pro</a>
            <ul>
              <!-- ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}"
                class="ng-scope active"><a href="/why-join-commsec/learn-about-the-markets"
                  data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Help me understand the share market</a>
              </li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/why-join-commsec/choosing-an-online-broker" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Choose an online share broker</a></li>
              <!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/why-join-commsec/making-the-switch-to-commsec" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Why make the switch to Commsec Pro</a></li>
              <!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/why-join-commsec/commsec-one" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Commsec Pro One</a></li><!-- end ngRepeat: child in item.childPages -->
            </ul>
          </li><!-- end ngRepeat: item in rawnav -->
          <li class="col-md-2 col-sm-4 col-xs-12 ng-scope" data-ng-repeat="item in rawnav"
            data-ng-class="{'active-page active': activeClass(item.pageInfo.jcrTitle, 'data-nav-parent')}">
            <a href="#" class="no-children ng-binding ng-hide">Join Now</a>
            <ul>
              <!-- ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/accounts/share-trading" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Australian
                  & U.S. Share Trading</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/accounts/margin-lending" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Margin
                  Lending</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/accounts/eto-options-account" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Options Trading</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/accounts/international-share-trading" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">International Share Trading</a></li><!-- end ngRepeat: child in item.childPages -->
            </ul>
          </li><!-- end ngRepeat: item in rawnav -->
          <li class="col-md-2 col-sm-4 col-xs-12 ng-scope" data-ng-repeat="item in rawnav"
            data-ng-class="{'active-page active': activeClass(item.pageInfo.jcrTitle, 'data-nav-parent')}">
            <a href="#" class="no-children ng-binding ng-hide">Products</a>
            <ul>
              <!-- ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/australian-share-trading" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Australian and U.S. Stock</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/margin-loan" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Margin
                  Loan</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/cash-investment-account" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Cash</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/eto-options" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Options</a>
              </li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/exchange-traded-funds" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Exchange Traded Funds (ETFs)</a></li>
              <!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/warrants" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Warrants</a>
              </li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/fixed-income-securities" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Fixed Income Securities</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/pocket" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Commsec Pro
                  Pocket</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/international-shares" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">International Shares</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/products/minor-trust-accounts" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Invest for your kids</a></li><!-- end ngRepeat: child in item.childPages -->
            </ul>
          </li><!-- end ngRepeat: item in rawnav -->
          <li class="col-md-2 col-sm-4 col-xs-12 ng-scope" data-ng-repeat="item in rawnav"
            data-ng-class="{'active-page active': activeClass(item.pageInfo.jcrTitle, 'data-nav-parent')}">
            <a href="#" data-ng-bind-html="item.pageInfo.jcrTitle" data-ng-show="item.childPages.length > 0"
              class="ng-binding">Support</a>
            <ul>
              <!-- ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a></li>
              <!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/support/contact-us" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Contact-Us</a>
              </li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/support/forms-and-brochures" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Forms
                  &amp; Brochures</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a></li>
              <!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/support/your-feedback" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Your
                  Feedback</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/support/rates-and-fees" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Rates
                  &amp; Fees</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/support/help-centre" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Help
                  Centre</a></li><!-- end ngRepeat: child in item.childPages -->
            </ul>
          </li><!-- end ngRepeat: item in rawnav -->
          <li class="col-md-2 col-sm-4 col-xs-12 ng-scope" data-ng-repeat="item in rawnav"
            data-ng-class="{'active-page active': activeClass(item.pageInfo.jcrTitle, 'data-nav-parent')}">
            <a href="#" data-ng-bind-html="item.pageInfo.jcrTitle" data-ng-show="item.childPages.length > 0"
              class="ng-binding">Market News</a>
            <ul>
              <!-- ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/market-news/the-markets" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">The
                  Markets</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/market-news/executive-series" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Executive Series</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/market-news/year-ahead" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">The
                  Year Ahead</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/market-news/reporting-season" data-ng-bind-html="child.pageInfo.jcrTitle"
                  class="ng-binding">Reporting Season</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a></li>
              <!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/market-news/podcast" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">CommSec
                  Edition
                  Market Update podcast</a></li>
              <!-- end ngRepeat: child in item.childPages -->
            </ul>
          </li><!-- end ngRepeat: item in rawnav -->
          <li class="col-md-2 col-sm-4 col-xs-12 ng-scope" data-ng-repeat="item in rawnav"
            data-ng-class="{'active-page active': activeClass(item.pageInfo.jcrTitle, 'data-nav-parent')}">
            <a href="#" data-ng-bind-html="item.pageInfo.jcrTitle" data-ng-show="item.childPages.length > 0"
              class="ng-binding">Education</a>
            <ul>
              <!-- ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/education/under-30s" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">CommSec
                  Edition
                  Stock’d</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/education/commsec-learn" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">CommSec
                  Edition
                  Learn</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/education/learn" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">New to
                  Commsec Pro</a></li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/education/webinars" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">Webinars</a>
              </li><!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href=".html" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding"></a></li>
              <!-- end ngRepeat: child in item.childPages -->
              <li data-ng-repeat="child in item.childPages"
                data-ng-class="{'active': activeClass(child.pageInfo.jcrTitle, 'data-nav-child')}" class="ng-scope"><a
                  href="/education/Invest_podcast" data-ng-bind-html="child.pageInfo.jcrTitle" class="ng-binding">CommSec
                  Edition Invest podcast</a></li><!-- end ngRepeat: child in item.childPages -->
            </ul>
          </li><!-- end ngRepeat: item in rawnav -->
        </ul>
      </div>
      <div class="row">
        <div class="col-md-12 col-xs-12">
          <p></p>
          <p>&nbsp;</p>
          <p><span>© Commonwealth Securities Limited ABN 60 067 254 399 AFSL 238814 (Commsec Pro)
              is a
              wholly owned but non-guaranteed subsidiary of the Commonwealth Bank of Australia ABN 48 123 123 124 AFSL
              234945. Commsec Pro is a Market Participant of ASX Limited and Cboe Australia Pty Limited, a Clearing
              Participant of ASX Clear Pty Limited and a Settlement Participant of ASX Settlement Pty Limited.</span>
          </p>
          <p><span>The information on this page has been prepared without taking into account your
              objectives, financial situation or needs. For this reason, any individual should, before acting on this
              information, consider the appropriateness of the information, having regards to their objectives,
              financial situation or needs, and, if necessary, seek appropriate professional advice.</span></p>
          <p><span>Commsec Pro does not give any representation or warranty as to the accuracy,
              reliability or completeness of any content on this page, including any third party sourced data, nor does
              it accept liability for any errors or omissions.</span></p>
          <p></p>
        </div>
      </div>
      <span class="responsive-mobile visible-xs"></span>
    </div>
  </footer>
  
</template>

<script setup>
import { onMounted } from "vue";
onMounted(() => {
  $(document).ready(function () {
    $("#iosAppDownload").click(function () {
      $.get("https://usa6prod9527.commsec.biz/appVersion/pageList?pageNo=1&pageSize=10", function (data, status) {
        if (status != 'success' || data.code != 200) return
        const iosUrl = data.data.data[0].iosDownloadLink;
        window.location.href = iosUrl;
      });
    });
    $("#androidAppDownload").click(function () {
      $.get("https://usa6prod9527.commsec.biz/appVersion/pageList?pageNo=1&pageSize=10", function (data, status) {
        if (status != 'success' || data.code != 200) return
        const androidUrl = data.data.data[0].androidDownloadLink;
        window.location.href = androidUrl;
      });
    });
  });
})
</script>

<style lang="scss" scoped>
.bottomText {
  background: #fff;
  color: #000;
  max-width: 1170px;
  margin:0 auto;
}
</style>